.ag-theme-quartz .ag-cell-value {
  vertical-align: middle;
  line-height: 20px;
}

.ag-theme-quartz .ag-cell-wrapper {
  height: 100%;
}

.ag-theme-quartz.with-cell-borders {
  --ag-cell-horizontal-border: solid var(--ag-row-border-color);
}

.ag-theme-quartz.with-darker-header {
  --ag-header-background-color: theme(colors.gray.100);
}

.ag-tooltip {
  background-color: #1f2937;
  color: #ffffff;
}

.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-quartz .ag-layout-auto-height .ag-center-cols-viewport {
  min-height: 60px;
}
